import _, { Dictionary } from "lodash";

export interface IConfigurationIntegrations {
  deeplApiKey?: string;
  googleMapsApiKey?: string;
  googleAnalyticsApiKey?: string;
  metaPixelId?: string;
}
export interface IConfigurationCoordinates {
  lat: number;
  lng: number;
}
export interface IConfigurationAddress {
  address?: string;
  extra?: string;
  city?: string;
  postCode?: string;
  countryCode?: string;
  coordinates?: IConfigurationCoordinates;
}
export interface IConfigurationMeta {
  owner?: string;
  address?: IConfigurationAddress;
  emailAddress?: string;
  emailSender?: string;
}
export interface IConfiguration {
  locales: string[];
  defaultLocale: string;
  tenants: string[];
  defaultTenant: string;
  api: Dictionary<string>;
  integrations?: IConfigurationIntegrations;
  version: string,
}

export enum ConfigurationApis {
  Core = 'core',
  Auth = 'auth',
  Authority = 'authority',
  Notifications = 'notifications',
  Content = 'content',
  Stable = 'stable',
  Booking = 'booking',
  Accountancy = 'accountancy',
  Payments = 'payments',
  Licensing = 'licensing'
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { ENV: any; }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEnvironmentVariable = (key: string): any => {
  const pureKey = key;
  key = `REACT_APP_${key.toUpperCase()}`;
  let value;
  if (process.env && _.has(process.env, key)) {
    value = process.env[key];
  } else if (window && window.ENV && _.has(window.ENV, key)) {
    value = window.ENV[key];
  }
  if (!value) {
    console.error(`Configuration key ${pureKey} not found`);
    return undefined;
  }
  if (value[0] === '{' || value[0] === '[') {
    return JSON.parse(value);
  } else {
    return value;
  }
}

const configuration = {
  locales: getEnvironmentVariable('locales'),
  defaultLocale: getEnvironmentVariable('default_locale'),
  tenants: getEnvironmentVariable('tenants'),
  defaultTenant: getEnvironmentVariable('default_tenant'),
  api: getEnvironmentVariable('api'),
  integrations: getEnvironmentVariable('integrations'),
  version: "0.1.11"
} as IConfiguration;

export const getApiBaseUrl = (key: ConfigurationApis): string => {
  if (!_.has(configuration.api, key)) {
    throw new Error("Invalid API");
  }
  return configuration.api[key];
}

export default configuration;

